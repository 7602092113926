import type { PiletApi, PiralPlugin } from 'piral';
import { FetchConfig, PiletFetchApi, createFetchApi } from 'piral-fetch';
import { isApiErrorResponseBody } from '../../types/fetch';
import { FetchOptionsWithExtras } from '../../types/services/fetch-options';
import {
	IFetchFunctions,
	IServicesApi,
} from '../../types/services/service-api';
import { FetchError } from '../errors/fetch-error';
import { getToken } from '../hooks/auth';
import getClientService from './client-service';
import getConfluenceService from './confluence-service';
import getJiraService from './jira-service';
import getNokoService from './noko-service';
import getUserService from './user-service';

export default function createServiceApi(): PiralPlugin<IServicesApi> {
	return (context) => {
		return (api: PiletApi): IServicesApi => {
			const config: FetchConfig = {
				default: {
					headers: { 'content-type': 'application/json' },
				},
				base: process.env.HUB_API_URL,
			};
			const fetchApi = createFetchApi(config)(context) as PiletFetchApi;
			const fetch = createCustomFetchApi(api, fetchApi);
			const fetchFunctions: IFetchFunctions = {
				fetch,
				fetchGet: (path, options) => {
					return fetch(path, { ...options, method: 'get' });
				},
				fetchPut: (path, options) => {
					return fetch(path, { ...options, method: 'put' });
				},
				fetchPost: (path, options) => {
					return fetch(path, { ...options, method: 'post' });
				},
				fetchDelete: (path, options) => {
					return fetch(path, { ...options, method: 'delete' });
				},
			};
			return {
				...fetchFunctions,
				serviceApi: {
					users: getUserService(fetchFunctions),
					noko: getNokoService(fetchFunctions),
					jira: getJiraService(fetchFunctions),
					confluence: getConfluenceService(fetchFunctions),
					clients: getClientService(fetchFunctions),
				},
			};
		};
	};
}

const createCustomFetchApi = (piletApi: PiletApi, fetchApi: PiletFetchApi) => {
	return async <T>(
		path: string,
		options: FetchOptionsWithExtras = {},
	): Promise<T> => {
		if (!options.headers) {
			options.headers = {};
		}

		if (!options.headers.Authorization) {
			options.headers.Authorization =
				getToken() !== '' ? `Bearer ${getToken()}` : '';
		}

		const result = await fetchApi.fetch<T>(path, options);

		if (result.code < 200 || result.code >= 300) {
			const errorResponseBody = result.body;
			let message = result.text;
			if (isApiErrorResponseBody<T>(errorResponseBody)) {
				if (!options.noNotifications) {
					const requestId = `Request ID: ${errorResponseBody.requestId}`;

					message = errorResponseBody.message;

					const toastBody =
						errorResponseBody.message !== result.text
							? `${errorResponseBody.message}, ${requestId}`
							: requestId;

					piletApi.showNotification(toastBody, {
						type: 'error',
						title: result.text,
						autoClose: 5000,
					});
				}
			}
			throw new FetchError(
				message,
				result.code,
				result.text,
				isApiErrorResponseBody<T>(errorResponseBody)
					? errorResponseBody
					: undefined,
			);
		}

		return result.body;
	};
};

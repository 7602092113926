import { HContainer } from '@hub/ui-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import {usePiletApi} from "piral";
import {FetchOptionsWithSignal} from "../../../types/services/fetch-options";

type QueryParams = {
	name?: string;
	type?: 'all' | 'individual' | 'business' | 'public';
	isPriorityClient?: boolean | null;
};

export default () => {
	const [search, setSearch] = useState<QueryParams>({
		name: '',
		type: 'all',
		isPriorityClient: false,
	});

	const history = useHistory();
	const piletApi = usePiletApi();

	const openClient = (id: number) => {
		history.push(`/clients/${id}`);
	};

	const typeToBadge = {
		individual: (
			<span className="px-2.5 py-0.5 text-xs font-medium bg-amber-100 text-amber-800">
				Eraisik
			</span>
		),
		business: (
			<span className="px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800">
				Ettevõte
			</span>
		),
		public: (
			<span className="px-2.5 py-0.5 text-xs font-medium bg-green-100 text-green-800">
				Avalik sektor
			</span>
		),
	};

	function triggerSearch(e) {
		e.preventDefault();
		runSearchClient();
	}

	function searchClients(params: QueryParams, { signal }: FetchOptionsWithSignal) {
		const searchParams: {
			name?: string;
			type?: 'individual' | 'business' | 'public';
			isPriorityClient?: true;
		} = {};
		if (params.name) searchParams.name = params.name;
		if (params.type && params.type !== 'all') searchParams.type = params.type;
		if (params.isPriorityClient) searchParams.isPriorityClient = true;

		return piletApi.serviceApi.clients.searchClients(searchParams, { signal });
	}

	const { data: clients, refetch: runSearchClient } = useQuery({
		queryKey: ['clients'],
		queryFn: (options) => searchClients(search, options),
	});

	return (
		<HContainer className="mt-4">
			<div className="relative overflow-hidden bg-white shadow-md">
				<div className="mx-4 border-b">
					<div className="flex items-center justify-between pt-3">
						<div className="flex flex-1 items-center">
							<h5 className="font-bolder text-2xl">Kliendid</h5>
						</div>
					</div>
					<div className="flex items-center justify-between py-3 flex-row">
						<div className="flex flex-row items-center w-2/3">
							<form className="w-full flex-1 mr-4 max-w-96">
								<div className="relative">
									<div className="pointer-events-none absolute top-0 bottom-0 left-0 flex items-center pl-3">
										<svg
											aria-hidden="true"
											className="w-4 h-4 text-gray-500"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
											/>
										</svg>
									</div>
									<input
										type="search"
										id="default-search"
										className="block w-full border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500"
										placeholder="Otsi nime järgi..."
										onChange={(e) =>
											setSearch({ ...search, name: e.target.value })
										}
									/>
									<button
										type="submit"
										className="absolute top-0 right-0 bottom-0 px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-300"
										onClick={(e) => triggerSearch(e)}
									>
										Otsi
									</button>
								</div>
							</form>
						</div>
						<div className="flex flex-shrink-0 justify-end mb-0 w-auto flex-row items-center">
							<button
								type="button"
								className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-300"
								onClick={() => history.push('/clients/create')}
							>
								<svg
									className="mr-2 h-3.5 w-3.5"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
									aria-hidden="true"
								>
									<path
										clipRule="evenodd"
										fillRule="evenodd"
										d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
									/>
								</svg>
								Lisa klient
							</button>
						</div>
					</div>
				</div>
				<div className="flex flex-wrap pb-3 mx-4">
					<div className="mr-4 mt-3 items-center text-sm font-medium text-gray-900 flex">
						Kuva:
					</div>
					<div className="flex flex-wrap">
						<div className="flex items-center mr-4 mt-3">
							<input
								id="all-clients"
								type="radio"
								value="all"
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
								checked={search.type === 'all'}
								onChange={(e) =>
									setSearch({
										...search,
										type: e.target.value as QueryParams['type'],
									})
								}
							/>
							<label
								htmlFor="all-clients"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								Kõik
							</label>
						</div>
						<div className="flex items-center mr-4 mt-3">
							<input
								id="individual"
								type="radio"
								value="individual"
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
								checked={search.type === 'individual'}
								onChange={(e) =>
									setSearch({
										...search,
										type: e.target.value as QueryParams['type'],
									})
								}
							/>
							<label
								htmlFor="individual"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								Eraisikud
							</label>
						</div>
						<div className="flex items-center mr-4 mt-3">
							<input
								id="business"
								type="radio"
								value="business"
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
								checked={search.type === 'business'}
								onChange={(e) =>
									setSearch({
										...search,
										type: e.target.value as QueryParams['type'],
									})
								}
							/>
							<label
								htmlFor="business"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								Ettevõtted
							</label>
						</div>
						<div className="flex items-center mr-4 mt-3">
							<input
								id="public"
								type="radio"
								value="public"
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
								checked={search.type === 'public'}
								onChange={(e) =>
									setSearch({
										...search,
										type: e.target.value as QueryParams['type'],
									})
								}
							/>
							<label
								htmlFor="public"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								Avalik sektor
							</label>
						</div>
						<div className="flex items-center mr-4 mt-3">
							<input
								id="high-priority"
								type="checkbox"
								value=""
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
								checked={search.isPriorityClient}
								onChange={(e) =>
									setSearch({ ...search, isPriorityClient: e.target.checked })
								}
							/>
							<label
								htmlFor="high-priority"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								P1 kliendid
							</label>
						</div>
					</div>
				</div>
				<div className="overflow-x-auto">
					<table className="w-full text-left text-sm text-gray-500">
						<thead className="bg-gray-50 text-xs uppercase text-gray-700">
							<tr>
								<th scope="col" className="py-3 px-4">
									Klient
								</th>
								<th scope="col" className="py-3 px-4">
									Liik
								</th>
							</tr>
						</thead>
						<tbody>
							{clients?.items.map((c) => (
								<tr
									key={c.id}
									className="border-b cursor-pointer hover:bg-gray-50"
									onKeyDown={(e) =>
										e.key === 'enter' ? openClient(c.id) : null
									}
									onClick={() => openClient(c.id)}
								>
									<td className="py-2 px-4 font-medium text-gray-900">
										{c.isPriorityClient && (
											<span className="px-2.5 py-0.5 mr-1 text-xs font-medium bg-red-100 text-red-800">
												P1
											</span>
										)}
										{c.name}
									</td>
									<td className="py-2 px-4 text-nowrap">
										{typeToBadge[c.type]}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{clients?.meta && (
						<p className="py-2 px-4 font-medium text-gray-900">
							Kokku: {clients.meta.totalElements}
						</p>
					)}
				</div>
			</div>
		</HContainer>
	);
};

import { IApiErrorResponse } from '../../types';

export class FetchError extends Error {
	constructor(
		message: string,
		public code: number,
		public text: string,
		public errorResponse?: IApiErrorResponse,
	) {
		super(message);
	}
}

import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useEffect } from 'react';
import { syncAuthWithBackend } from '../../auth/client';
import { setToken } from '../../hooks/auth';

export default () => {
	const queryClient = useQueryClient();

	// When this mutation succeeds set new token.
	// This inherently invalidates all queries.
	const { error, isPending, mutate } = useMutation({
		mutationFn: syncAuthWithBackend,
		onSuccess: (data) => {
			setToken(data, queryClient);
		},
	});

	// Run the mutation once on page load
	useEffect(() => {
		mutate();
	}, [mutate]);

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				color: error ? 'red' : 'black',
			}}
		>
			{isPending ? 'Loading...' : null}
			{error ? (
				<p
					style={{
						maxWidth: '30rem',
					}}
				>
					Error: {error.message}
				</p>
			) : null}
		</div>
	);
};

import {HContainer} from '@hub/ui-react';
import {useMutation} from '@tanstack/react-query';
import * as React from 'react';
import {useState} from 'react';
import {useHistory} from 'react-router';
import {IPaginatedResponse} from '../../../types';
import {IClientResponse} from '../../../types/services/client-service';
import {getToken} from '../../hooks/auth';
import {fetchWithErrorHandling} from '../../utils/fetch';
import {usePiletApi} from "piral";

interface IClientCreateRequest {
  name: string;
  type: string;
  identifier: string;
  email: string;
  phone: string;
  website: string;
  isPriorityClient: boolean;
}

export default () => {
  const history = useHistory();
  const piletApi = usePiletApi();

  const [client, setClient] = useState<IClientCreateRequest>({
    name: '',
    type: '',
    identifier: '',
    email: '',
    phone: '',
    website: '',
    isPriorityClient: false,
  });

  const {mutateAsync: createClient} = useMutation({
    mutationFn: (newClient: IClientCreateRequest) => {
      return piletApi.fetchPost<IClientResponse>('/v1/clients', {
        body: JSON.stringify(newClient),
      });
    },
  });

  async function handleCreateClient(e) {
    e.preventDefault();
    await createClient(client);
    history.replace('/clients');
  }

  return (
      <HContainer className="mt-4">
        <div className="relative overflow-hidden bg-white shadow-md">
          <div className="mx-4 border-b">
            <div className="flex items-center justify-between pt-3">
              <div className="flex flex-1 items-center">
                <h5 className="font-bolder text-lg">Lisa uus klient</h5>
              </div>
            </div>
          </div>
          <div className="p-4">
            <form action="#" onSubmit={handleCreateClient}>
              <div className="grid gap-4 mb-4 sm:grid-cols-2">
                <div>
                  <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Nimi
                  </label>
                  <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required
                      onChange={(e) => setClient({...client, name: e.target.value})}
                  />
                </div>
                <div>
                  <label
                      htmlFor="type"
                      className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Liik
                  </label>
                  <select
                      id="type"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                      required
                      onChange={(e) => setClient({...client, type: e.target.value})}
                  >
                    <option defaultValue="">Vali liik</option>
                    <option value="individual">Eraisik</option>
                    <option value="business">Ettevõte</option>
                    <option value="public">Avalik sektor</option>
                  </select>
                </div>
                <div>
                  <label
                      htmlFor="identifier"
                      className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Isikukood / Registrikood
                  </label>
                  <input
                      type="text"
                      name="identifier"
                      id="identifier"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required
                      onChange={(e) => setClient({...client, identifier: e.target.value})}
                  />
                </div>
                <div>
                  <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kliendi üldemail
                  </label>
                  <input
                      type="text"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required
                      onChange={(e) => setClient({...client, email: e.target.value})}
                  />
                </div>
                <div>
                  <label
                      htmlFor="phone"
                      className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kliendi üldtelefon
                  </label>
                  <input
                      type="text"
                      name="phone"
                      id="phone"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required
                      onChange={(e) => setClient({...client, phone: e.target.value})}
                  />
                </div>
                <div>
                  <label
                      htmlFor="website"
                      className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kliendi kodulehekülg.
                  </label>
                  <input
                      type="text"
                      name="website"
                      id="website"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      required
                      onChange={(e) => setClient({...client, website: e.target.value})}
                  />
                </div>
                <div className="sm:col-span-2">
                  <div className="flex items-center">
                    <input
                        id="high-priority"
                        type="checkbox"
                        value=""
                        name="show-only"
                        className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
                        checked={client.isPriorityClient}
                        onChange={(e) => setClient({...client, isPriorityClient: e.target.checked})}
                    />
                    <label
                        htmlFor="high-priority"
                        className="ml-2 text-sm font-medium text-gray-900"
                    >
                      Märgi P1 kliendiks
                    </label>
                  </div>
                </div>
              </div>
              <button
                  type="submit"
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-300"
              >
                <svg
                    className="mr-1 -ml-1 w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <title>add client icon</title>
                  <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd"
                  />
                </svg>
                Lisa klient
              </button>
            </form>
          </div>
        </div>
      </HContainer>
  );
};

import { HButton, HCol, HContainer, HRow } from '@hub/ui-react';
import * as React from 'react';
import { authClient } from '../../auth/client';

export default () => {
	return (
		<HContainer className="layout layout-auth w-full h-full" fillHeight>
			<HRow justifyContent="center" className="w-full">
				<HCol size={12}>
					<HButton
						className="self-center"
						color="primary"
						onClick={authClient.handleAuthentication}
					>
						Log in with SSO
					</HButton>
				</HCol>
			</HRow>
		</HContainer>
	);
};

import {
	HAppBar,
	HAppBarTitle,
	HDropdown,
	HMenu,
	HMenuItem,
	HSpacer,
} from '@hub/ui-react';
import { useQueryClient } from '@tanstack/react-query';
import { MenuContainerProps, useGlobalState } from 'piral';
import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/twn-colored-small.svg';
import { setToken } from '../../hooks/auth';

export const Navigation: FC<MenuContainerProps> = ({ children }) => {
	return (
		<HAppBar className="bg-white shadow-md fixed top-0 left-0 right-0 z-10">
			<HAppBarTitle>
				<Link to="/">
					<Logo />
				</Link>
			</HAppBarTitle>
			<HSpacer />
			{children}
			<HSpacer />
			<AuthNavigation />
		</HAppBar>
	);
};

const AuthNavigation = () => {
	const user = useGlobalState((s) => s.user);
	const queryClient = useQueryClient();
	if (typeof user === 'undefined') {
		return null;
	}

	return (
		<HDropdown>
			<div slot="activator">
				<p
					style={{
						fontSize: '14px',
					}}
				>
					Sisse logitud:
				</p>
				<p
					slot="activator"
					style={{
						cursor: 'pointer',
					}}
				>
					{user.firstName} {user.lastName}
				</p>
			</div>
			<HMenu>
				<HMenuItem>Profile</HMenuItem>
				<HMenuItem>Settings</HMenuItem>
				<HMenuItem onClick={() => setToken('', queryClient)}>
					Sign out
				</HMenuItem>
			</HMenu>
		</HDropdown>
	);
};

import { HContainer } from '@hub/ui-react';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

export const ProjectList = () => {
	const history = useHistory();

	const openProject = () => {
		history.push('/projects/1');
	};

	return (
		<HContainer>
			<div className="relative overflow-hidden bg-white shadow-md">
				<div className="mx-4 border-b">
					<div className="flex items-center justify-between pt-3">
						<div className="flex flex-1 items-center">
							<h5 className="font-bolder">Projects</h5>
						</div>
					</div>
					<div className="flex items-center justify-between py-3 flex-row">
						<div className="flex flex-row items-center w-2/3">
							<form className="w-full flex-1 mr-4 max-w-96">
								<div className="relative">
									<div className="pointer-events-none absolute top-0 bottom-0 left-0 flex items-center pl-3">
										<svg
											aria-hidden="true"
											className="w-4 h-4 text-gray-500"
											fill="none"
											stroke="currentColor"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
											/>
										</svg>
									</div>
									<input
										type="search"
										id="default-search"
										className="block w-full border border-gray-300 bg-gray-50 p-2 pl-10 text-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500"
										placeholder="Search..."
										required
									/>
									<button
										type="submit"
										className="absolute top-0 right-0 bottom-0 px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-300"
									>
										Search
									</button>
								</div>
							</form>
						</div>
						<div className="flex flex-shrink-0 justify-end mb-0 w-auto flex-row items-center">
							<Link to="/projects/create">
								<button
									type="button"
									className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-300"
								>
									<svg
										className="mr-2 h-3.5 w-3.5"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
										aria-hidden="true"
									>
										<path
											clipRule="evenodd"
											fillRule="evenodd"
											d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
										/>
									</svg>
									New project
								</button>
							</Link>
						</div>
					</div>
				</div>
				<div className="flex flex-wrap pb-3 mx-4">
					<div className="mr-4 mt-3 items-center text-sm font-medium text-gray-900 flex">
						Show only:
					</div>
					<div className="flex flex-wrap">
						<div className="flex items-center mr-4 mt-3">
							<input
								id="all-tasks"
								type="radio"
								value=""
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
								checked
							/>
							<label
								htmlFor="all-tasks"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								All
							</label>
						</div>
						<div className="flex items-center mr-4 mt-3">
							<input
								id="completed"
								type="radio"
								value=""
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
							/>
							<label
								htmlFor="completed"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								Completed
							</label>
						</div>
						<div className="flex items-center mr-4 mt-3">
							<input
								id="in-progress"
								type="radio"
								value=""
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
							/>
							<label
								htmlFor="in-progress"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								In Progress
							</label>
						</div>
						<div className="flex items-center mr-4 mt-3">
							<input
								id="in-review"
								type="radio"
								value=""
								name="show-only"
								className="h-4 w-4 border-gray-300 bg-gray-100 text-indigo-600 focus:ring-indigo-500"
							/>
							<label
								htmlFor="in-review"
								className="ml-2 text-sm font-medium text-gray-900"
							>
								Archived
							</label>
						</div>
					</div>
				</div>
				<div className="overflow-x-auto">
					<table className="w-full text-left text-sm text-gray-500">
						<thead className="bg-gray-50 text-xs uppercase text-gray-700">
							<tr>
								<th scope="col" className="py-3 px-4">
									Project
								</th>
								<th scope="col" className="py-3 px-4">
									Client
								</th>
								<th scope="col" className="py-3 px-4">
									Status
								</th>
								<th scope="col" className="py-3 px-4">
									Members
								</th>
								<th scope="col" className="py-3 px-4">
									Progress
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								className="border-b cursor-pointer hover:bg-gray-50"
								onKeyDown={(e) => (e.key === 'enter' ? openProject() : null)}
								onClick={() => openProject()}
							>
								<td className="py-2 px-4 font-medium text-gray-900">
									E-Service 2.0
								</td>
								<td className="py-2 px-4 text-nowrap">SK ID Solutions AS</td>
								<td className="py-2 px-4 text-nowrap">
									<span className="px-2.5 py-0.5 text-xs font-medium bg-indigo-100 text-indigo-800">
										In progress
									</span>
								</td>
								<td className="py-2 px-4 text-nowrap">
									<div className="flex w-28 -space-x-4">
										<img
											src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/avatar-10.png"
											alt=""
											className="h-10 w-10 flex-shrink-0 rounded-full border-2 border-white"
										/>
										<img
											src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/avatar-1.png"
											alt=""
											className="h-10 w-10 flex-shrink-0 rounded-full border-2 border-white"
										/>
										<img
											src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/avatar-3.png"
											alt=""
											className="h-10 w-10 flex-shrink-0 rounded-full border-2 border-white"
										/>
										<button
											type={'button'}
											className="flex w-10 h-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-white bg-gray-900 text-xs font-medium text-white"
										>
											+5
										</button>
									</div>
								</td>
								<td className="py-2 px-4 text-nowrap">
									<div className="mb-2 flex justify-end">
										<span className="text-xs font-medium text-gray-500">
											75%
										</span>
									</div>
									<div className="h-1.5 w-full rounded-full bg-gray-200">
										<div
											className="bg-indigo-600 h-1.5 rounded-full"
											style={{ width: '75%' }}
										/>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</HContainer>
	);
};

import { HCard, HCardTitle, HCol, HContainer, HRow } from '@hub/ui-react';
import * as React from 'react';

export const ProjectDetailed = () => (
	<div className="project-dashboard">
		<nav className="bg-gray-800">
			<div className="py-3 px-4 flex flex-wrap justify-between items-center">
				<div className="flex items-center mb-2 sm:mb-0">
					<button
						id="dropdownUserNameButton"
						data-dropdown-toggle="dropdownUserName"
						data-dropdown-placement="bottom"
						className="flex items-center justify-between rounded-lg p-2 bg-gray-800 hover:bg-gray-700"
						type="button"
					>
						<div className="flex items-center mr-2">
							<img
								src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
								className="mr-2 h-7 w-7 rounded-full"
								alt="Bonnie avatar"
							/>
							<div className="text-left">
								<div className="text-sm font-semibold leading-4 text-white">
									SK ID Solutions AS
								</div>
							</div>
						</div>
					</button>
					<svg
						className="mx-2 h-3 w-3 text-gray-400"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 6 10"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="m1 9 4-4-4-4"
						/>
					</svg>
					<button
						type="button"
						id="regionDropdownButton"
						data-dropdown-toggle="regionDropdown"
						className="items-center rounded-lg p-2 pr-3 pl-4 text-sm font-medium md:inline-flex text-gray-400 hover:bg-gray-700"
					>
						E-Service 2.0
					</button>
				</div>
				<div className="grid grid-cols-2 w-full gap-2 sm:flex sm:items-center sm:w-auto">
					<button
						type="button"
						className="rounded-lg px-4 py-2.5 text-xs font-medium text-white mr-2 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-800"
					>
						Open app
					</button>
					<button
						id="moreOptionsDropdownButton"
						type="button"
						data-dropdown-toggle="moreOptionsDropdown"
						className="inline-flex items-center justify-center rounded-lg border px-4 py-2.5 text-xs font-medium bg-gray-800 border-indigo-500 text-indigo-500 hover:text-white hover:bg-indigo-700 focus:ring-indigo-800"
					>
						More{' '}
						<svg
							className="ml-1.5 h-2.5 w-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 10 16"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M9 5 5 1 1 5m0 6 4 4 4-4"
							/>
						</svg>
					</button>
				</div>
			</div>
		</nav>
		<nav className="bg-gray-900 block border-gray-800">
			<div className="px-6">
				<div className="flex items-center">
					<ul className="flex w-full text-sm font-medium flex-row mt-0">
						<li className="inline border-b-0 border-gray-700">
							<button
								type={'button'}
								className="block px-4 py-3 border-b-2 border-indigo-500 text-indigo-500"
							>
								Summary
							</button>
						</li>
						<li className="inline border-b-0 border-gray-700">
							<button
								type={'button'}
								className="block px-4 py-3 text-gray-400 hover:border-b-2 hover:border-indigo-500 hover:text-indigo-500"
							>
								Info
							</button>
						</li>
						<li className="inline border-b-0 border-gray-700">
							<button
								type={'button'}
								className="block px-4 py-3 text-gray-400 hover:border-b-2 hover:border-indigo-500 hover:text-indigo-500"
							>
								Team
							</button>
						</li>
						<li className="inline border-b-0 border-gray-700 hover;border-b-2">
							<button
								type={'button'}
								className="block px-4 py-3 text-gray-400 hover:border-b-2 hover:border-indigo-500 hover:text-indigo-500"
							>
								Budget
							</button>
						</li>
						<li className="inline border-b-0 border-gray-700 hover;border-b-2">
							<button
								type={'button'}
								className="block px-4 py-3 text-gray-400 hover:border-b-2 hover:border-indigo-500 hover:text-indigo-500"
							>
								Resources
							</button>
						</li>
						<li className="inline border-b-0 border-gray-700 hover;border-b-2">
							<button
								type={'button'}
								className="block px-4 py-3 text-gray-400 hover:border-b-2 hover:border-indigo-500 hover:text-indigo-500"
							>
								Integrations
							</button>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<HContainer className="py-4">
			<HRow>
				<HCol size={8}>
					<HCard className="w-full">
						<HCardTitle>E-Service 2.0</HCardTitle>
					</HCard>
				</HCol>
				<HCol size={4}>
					<HCard className="w-full">
						<HCardTitle>E-Service 2.0</HCardTitle>
					</HCard>
				</HCol>
			</HRow>
		</HContainer>
	</div>
);

import {
	IConfluenceSearchResponse,
	IConfluenceSpace,
} from '../../types/services/confluence-service';
import {
	IFetchFunctions,
	IServicesApi,
} from '../../types/services/service-api';

const getConfluenceService = (
	fetchFunctions: IFetchFunctions,
): IServicesApi['serviceApi']['confluence'] => ({
	async search(queryParams, options) {
		const urlSearchParams = new URLSearchParams(
			queryParams as unknown as Record<string, string>,
		);
		return fetchFunctions.fetchGet<IConfluenceSearchResponse>(
			`/v1/atlassian/confluence/search?${urlSearchParams.toString()}`,
			options,
		);
	},
	async searchSpaces(queryParams, options) {
		const urlSearchParams = new URLSearchParams(
			queryParams as unknown as Record<string, string>,
		);
		return fetchFunctions.fetchGet<IConfluenceSearchResponse>(
			`/v1/atlassian/confluence/spaces/search?${urlSearchParams.toString()}`,
			options,
		);
	},
	async getSpaceByKey(key, options) {
		return fetchFunctions.fetchGet<IConfluenceSpace>(
			`/v1/atlassian/confluence/spaces/${key}`,
			options,
		);
	},
});
export default getConfluenceService;

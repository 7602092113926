import { HContainer } from '@hub/ui-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { IClientResponse } from '../../../../types/services/client-service';
import { getToken } from '../../../hooks/auth';
import { fetchWithErrorHandling } from '../../../utils/fetch';
import {FetchOptionsWithSignal} from "../../../../types/services/fetch-options";
import {usePiletApi} from "piral";

export default () => {
	const history = useHistory();
	const piletApi = usePiletApi();
	const { id } = useParams<{ id: string }>();
	const { isPending, data: client } = useQuery({
		queryKey: ['clients', id],
		queryFn: (options) => getClient(Number(id), options),
	});

	const typeToBadge = {
		individual: (
			<span className="px-2.5 py-0.5 text-xs font-medium bg-amber-100 text-amber-800">
				Eraisik
			</span>
		),
		business: (
			<span className="px-2.5 py-0.5 text-xs font-medium bg-blue-100 text-blue-800">
				Ettevõte
			</span>
		),
		public: (
			<span className="px-2.5 py-0.5 text-xs font-medium bg-green-100 text-green-800">
				Avalik sektor
			</span>
		),
	};

	async function getClient(id: number, { signal }: FetchOptionsWithSignal) {
		return piletApi.serviceApi.clients.getClient(id, { signal });
	}

	return (
		client && (
			<HContainer className="mt-4">
				<div className="relative overflow-hidden bg-white shadow-md">
					<div className="mx-4 border-b">
						<div className="flex items-center justify-between pt-3 mb-2">
							<div className="flex flex-1 items-center">
								{client.isPriorityClient && (
									<span className="px-2.5 py-0.5 mr-1 text-sm font-medium bg-red-100 text-red-800">
										P1
									</span>
								)}
								<h5 className="font-bolder text-2xl">{client.name}</h5>
							</div>
							<button
								type="button"
								className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-indigo-300 disabled:bg-gray-100 disabled:cursor-not-allowed"
								onClick={() => history.push(`/clients/${id}/edit`)}
								disabled
							>
								Muuda
							</button>
						</div>
					</div>

					<div className="mx-4">
						<dl className="divide-y divide-gray-100">
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Nimi
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{client.name}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Liik
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{typeToBadge[client.type]}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Isikukood / Registrikood
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{client.identifier}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Kliendi üldemail
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{client.email}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Kliendi üldtelefon
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{client.phone}
								</dd>
							</div>
							<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
								<dt className="text-sm font-medium leading-6 text-gray-900">
									Kliendi kodulehekülg
								</dt>
								<dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
									{client.website}
								</dd>
							</div>
						</dl>
					</div>
				</div>
			</HContainer>
		)
	);
};

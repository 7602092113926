import { Dayjs } from 'dayjs';
import {
	INokoEntryResponse,
	INokoListEntries,
	INokoListEntriesQueryParams,
	INokoListProjects,
	INokoProjectResponse,
	INokoUserResponse,
} from '../../types/services/noko-service';
import {
	IFetchFunctions,
	IServicesApi,
} from '../../types/services/service-api';

const formatEntriesListRequestParams = (
	params: INokoListEntriesQueryParams,
): Record<string, string> => {
	const formattedParams: Record<string, string> = {};
	if (params.per_page) {
		formattedParams.per_page = params.per_page.toString();
	}
	if (params.description) {
		formattedParams.description = params.description;
	}
	if (params.tag_filter_types) {
		formattedParams.tag_filter_types = params.tag_filter_types;
	}
	if (typeof params.invoiced === 'boolean') {
		formattedParams.invoiced = params.invoiced ? 'true' : 'false';
	}
	if (params.user_ids) {
		formattedParams.user_ids = formatIdsForNoko(params.user_ids);
	}
	if (params.project_ids) {
		formattedParams.project_ids = formatIdsForNoko(params.project_ids);
	}
	if (params.tag_ids) {
		formattedParams.tag_ids = formatIdsForNoko(params.tag_ids);
	}
	if (params.invoice_ids) {
		formattedParams.invoice_ids = formatIdsForNoko(params.invoice_ids);
	}
	if (params.import_ids) {
		formattedParams.import_ids = formatIdsForNoko(params.import_ids);
	}
	if (params.from) {
		formattedParams.from = formatDateForNoko(params.from, 'short');
	}
	if (params.to) {
		formattedParams.to = formatDateForNoko(params.to, 'short');
	}
	if (params.invoiced_at_from) {
		formattedParams.invoiced_at_from = formatDateForNoko(
			params.invoiced_at_from,
			'short',
		);
	}
	if (params.invoiced_at_to) {
		formattedParams.invoiced_at_to = formatDateForNoko(
			params.invoiced_at_to,
			'short',
		);
	}
	if (params.updated_from) {
		formattedParams.updated_from = formatDateForNoko(
			params.updated_from,
			'long',
		);
	}
	if (params.updated_to) {
		formattedParams.updated_to = formatDateForNoko(params.updated_to, 'long');
	}
	if (params.approved_by_ids) {
		formattedParams.approved_by_ids = formatIdsForNoko(params.approved_by_ids);
	}
	return formattedParams;
};

const formatIdsForNoko = (ids: number[]): string => {
	return ids.join(',');
};

const formatDateForNoko = (date: Dayjs, type: 'short' | 'long'): string => {
	return date.format(type === 'short' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:MM:SSZ');
};
const getNokoService = (
	fetchFunctions: IFetchFunctions,
): IServicesApi['serviceApi']['noko'] => ({
	async getCurrentNokoUser(options) {
		return fetchFunctions.fetchGet<INokoUserResponse>('/v1/noko/user', options);
	},
	async getCurrentNokoUserEntries(params, options) {
		const formattedParams = formatEntriesListRequestParams(params);
		const urlSearchParams = new URLSearchParams(formattedParams);
		return fetchFunctions.fetchGet<INokoListEntries>(
			`/v1/noko/user/entries?${urlSearchParams.toString()}`,
			options,
		);
	},
	async getEntry(nokoEntryId, options) {
		return fetchFunctions.fetchGet<INokoEntryResponse>(
			`/v1/noko/entries/${nokoEntryId}`,
			options,
		);
	},
	async getEntries(params, options) {
		const formattedParams = formatEntriesListRequestParams(params);
		const urlSearchParams = new URLSearchParams(formattedParams);
		return fetchFunctions.fetchGet<INokoListEntries>(
			`/v1/noko/entries?${urlSearchParams.toString()}`,
			options,
		);
	},
	async getProject(nokoProjectId, options) {
		return fetchFunctions.fetchGet<INokoProjectResponse>(
			`/v1/noko/projects/${nokoProjectId}`,
			options,
		);
	},
	async getProjects(queryParams, options) {
		const urlSearchParams = new URLSearchParams(
			queryParams as Record<string, string>,
		);
		return fetchFunctions.fetchGet<INokoListProjects>(
			`/v1/noko/projects?${urlSearchParams.toString()}`,
			options,
		);
	},
	async updateProjectData(nokoProjectId, data, options = {}) {
		await fetchFunctions.fetchPut<void>(`/v1/noko/projects/${nokoProjectId}`, {
			body: JSON.stringify(data),
			...options,
		});
		return;
	},
	async updateProjectsData(data, options = {}) {
		await fetchFunctions.fetchPut<void>('/v1/noko/projects', {
			body: JSON.stringify(data),
			...options,
		});
		return;
	},
});
export default getNokoService;

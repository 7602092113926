import { defineCustomElements } from '@hub/ui-react';
import { Piral, createInstance } from 'piral';
import { createAuthApi } from 'piral-auth';
import { createConfigsApi } from 'piral-configs';
import { createDashboardApi } from 'piral-dashboard';
import { createMenuApi } from 'piral-menu';
import { createNotificationsApi } from 'piral-notifications';
import { createOidcApi } from 'piral-oidc';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { authClient } from './auth/client';
import { errors, layout } from './layouts/default';

import { Link } from 'react-router-dom';
// TODO: Move to a Pilet
import { ProjectList } from './pages/mvp-projects';
import { ProjectCreate } from './pages/mvp-projects/create';
import { ProjectDetailed } from './pages/mvp-projects/detail';
import createServiceApi from './pilet-api/service-api';

const LoginPage = React.lazy(() => import('./pages/auth/login'));
const AuthPage = React.lazy(() => import('./pages/auth'));

const ClientsList = React.lazy(() => import('./pages/clients'));
const ClientsDetailed = React.lazy(() => import('./pages/clients/:id/detail'));
const ClientsCreate = React.lazy(() => import('./pages/clients/create'));
// const ClientsUpdate = React.lazy(() => import('./pages/clients/:id/update'))

defineCustomElements();

// change to your feed URL here (either using feed.piral.cloud or your own service)
const feedUrl = `${process.env.HUB_API_URL}/v1/pilet`;

const instance = createInstance({
	state: {
		components: layout,
		errorComponents: errors,
		initialLoading: true,
	},
	plugins: [
		createConfigsApi(),
		createNotificationsApi(),
		createMenuApi(),
		createOidcApi(authClient),
		createAuthApi(),
		createDashboardApi({
			routes: ['/'],
			defaultPreferences: {
				initialColumns: 3,
				initialRows: 3,
				resizable: false,
			},
		}),
		createServiceApi(),
	],
	async requestPilets() {
		return fetch(feedUrl)
			.then((res) => res.json())
			.then((res) => res.items);
	},
});

instance.root.registerPage('/auth', AuthPage);
instance.root.registerPage('/auth/login', LoginPage);

instance.root.registerPage('/clients', ClientsList);
instance.root.registerPage('/clients/create', ClientsCreate);
instance.root.registerPage('/clients/:id', ClientsDetailed);
// instance.root.registerPage('/clients/:id/edit', ClientsUpdate);

// TODO: For MVP showcase
instance.root.registerPage('/projects', ProjectList);
instance.root.registerPage('/projects/1', ProjectDetailed);
instance.root.registerPage('/projects/create', ProjectCreate);

instance.root.registerMenu('core:clients', () => (
	<Link to="/clients">Kliendid</Link>
));

const root = createRoot(document.querySelector('#app'));
root.render(<Piral instance={instance} />);

import { IPaginatedResponse } from '../../types';
import {
	IClientResponse,
	IClientSearchRequest,
} from '../../types/services/client-service';
import { FetchOptionsWithSignal } from '../../types/services/fetch-options';
import {
	IFetchFunctions,
	IServicesApi,
} from '../../types/services/service-api';

const getClientService = (
	fetchFunctions: IFetchFunctions,
): IServicesApi['serviceApi']['clients'] => ({
	searchClients(params: IClientSearchRequest, options: FetchOptionsWithSignal) {
		const searchParams = new URLSearchParams();
		for (const p in params) {
			searchParams.append(p, params[p]);
		}

		return fetchFunctions.fetchGet<IPaginatedResponse<IClientResponse>>(
			`/v1/clients/?${searchParams.toString()}`,
			options,
		);
	},
	getClient(id: number, options: FetchOptionsWithSignal) {
		return fetchFunctions.fetchGet<IClientResponse>(
			`/v1/clients/${id}`,
			options,
		);
	},
});

export default getClientService;

export function fetchWithErrorHandling<ResponseType>(
	path: string,
	init?: RequestInit,
): Promise<ResponseType> {
	const host = process.env.HUB_API_URL;

	const requestPath = path.startsWith('/') ? host + path : `${host}/${path}`;

	return fetch(requestPath, {
		...init,
		headers: {
			'Content-Type': 'application/json',
			...init?.headers,
		},
	}).then(async (response) => {
		if (response.status === 204) {
			return;
		}

		if (!response.ok) {
			const resp = await response.json();
			throw new Error(
				`Request failed! ${response.statusText}, ${resp.message}`,
			);
		}
		return response.json();
	});
}

import { QueryClient } from '@tanstack/react-query';
import { fetchWithErrorHandling } from '../utils/fetch';

const TOKEN_KEY = 'bearerToken';
const setLocalStorageToken = (token: string) => {
	if (token) {
		localStorage.setItem(TOKEN_KEY, token);
		return;
	}
	localStorage.removeItem(TOKEN_KEY);
};
export const getToken = () => localStorage.getItem(TOKEN_KEY) ?? '';

export const setToken = (newToken: string, queryClient: QueryClient) => {
	const oldToken = getToken();
	if (oldToken === newToken) {
		return;
	}
	if (oldToken) {
		fetchWithErrorHandling('v1/auth/logout', {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${oldToken}`,
			},
		}).catch((error) => {
			console.warn('Error when clearing tokens');
			console.error(error);
		});
	}
	setLocalStorageToken(newToken);
	// When token changes always invalidate all queries.
	// This allows us to easily re-fetch data on user change, if necessary in the future
	queryClient.invalidateQueries({ queryKey: ['withToken'] }).catch((error) => {
		console.error('Error when invalidating queries');
		console.error(error);
	});
};

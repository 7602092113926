import {
	IJiraFilterResponse,
	IJiraProjectResponse,
} from '../../types/services/jira-service';
import {
	IFetchFunctions,
	IServicesApi,
} from '../../types/services/service-api';

const getJiraService = (
	fetchFunctions: IFetchFunctions,
): IServicesApi['serviceApi']['jira'] => ({
	async filterProjects(queryParams, options) {
		const urlSearchParams = new URLSearchParams(
			queryParams as Record<string, string>,
		);
		return fetchFunctions.fetchGet<IJiraFilterResponse>(
			`/v1/atlassian/jira/projects/picker?${urlSearchParams.toString()}`,
			options,
		);
	},
	async getProjectByKey(key, options) {
		return fetchFunctions.fetchGet<IJiraProjectResponse>(
			`/v1/atlassian/jira/projects/${key}`,
			options,
		);
	},
});
export default getJiraService;

export interface IApiErrorResponse {
	statusCode: number;
	message: string;
	path: string;
	timestamp: string;
	requestId: string;

	[k: string]: unknown;
}

export const isApiErrorResponseBody = <T>(
	body: T | IApiErrorResponse,
): body is IApiErrorResponse => {
	return !!(body as IApiErrorResponse).requestId;
};

import { IPaginatedResponse } from '../../types';
import { FetchOptionsWithSignal } from '../../types/services/fetch-options';
import {
	IFetchFunctions,
	IServicesApi,
} from '../../types/services/service-api';
import { IUserResponse } from '../../types/services/user-service';

const getUserService = (
	fetchFunctions: IFetchFunctions,
): IServicesApi['serviceApi']['users'] => ({
	getUsers(name: string, email: string, options: FetchOptionsWithSignal) {
		const params = new URLSearchParams();
		params.append('name', name);
		params.append('email', email);
		return fetchFunctions.fetchGet<IPaginatedResponse<IUserResponse>>(
			`/v1/users/?${params.toString()}`,
			options,
		);
	},
	getMe(options: FetchOptionsWithSignal) {
		return fetchFunctions.fetchGet<IUserResponse>('/v1/users/me', options);
	},
});
export default getUserService;

import {
	HButton,
	HCard,
	HCardActions,
	HCardTitle,
	HContainer,
	HTextField,
} from '@hub/ui-react';
import * as React from 'react';

export const ProjectCreate = () => {
	return (
		<HContainer className="h-full justify-center" fillHeight>
			<HCard className="w-96">
				<HCardTitle className="text-center">Create project</HCardTitle>
				<div className="px-4">
					<form action="">
						<HTextField name="project_name" label="Project name" />
						<HTextField name="client" label="Client" />
					</form>
				</div>
				<HCardActions>
					<HButton>Next</HButton>
				</HCardActions>
			</HCard>
			<HCard className="w-96" />
		</HContainer>
	);
};

import { setupOidcClient } from 'piral-oidc';
import { fetchWithErrorHandling } from '../utils/fetch';

export const authClient = setupOidcClient({
	clientId: process.env.HUB_OAUTH_CLIENT,
	identityProviderUri: `${process.env.HUB_OAUTH_HOST}/${process.env.HUB_OAUTH_TENANT_ID}/v2.0`,
	appUri: `${process.env.HUB_API_URL}/v1/auth/dev`,
	redirectUri: `${window.location.origin}/auth`,
	responseType: 'code',
	scopes: ['profile', 'openid', 'email'],
});

export const syncAuthWithBackend = async () => {
	const params = new URLSearchParams(window.location.search);
	const authCode = params.get('code');
	const state = params.get('state');
	const code_verifier = JSON.parse(
		localStorage.getItem(`oidc.${state}`),
	).code_verifier;

	if (!authCode || !state || !code_verifier) {
		throw new Error('Missing auth code, state or code_verifier');
	}

	try {
		// Make a request to your backend API with tokens
		const response = await fetchWithErrorHandling<{ token: string }>(
			`/v1/auth/authorizationCode/${authCode}`,
			{
				method: 'POST',
				body: JSON.stringify({
					state: state,
					code_verifier: code_verifier,
				}),
			},
		);

		return response.token;
	} catch (error) {
		console.error('Error sending tokens to backend:', error);
		throw error;
	}
};
